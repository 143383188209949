import * as React from "react"

import {Link} from "gatsby";
import Platern from "/src/assets/platern.svg"

const NotFoundPage = () => (
  <div className="m-auto">
    <div
      className="m-auto md:inline-block w-full md:p-0 md:align-middle">
      <div className="flex h-screen">
        <div
          className="m-auto">
          <div>
            <Link to="/">
              <Platern
                className="header__logo dark:header__logo m-auto mb-4"
                alt="Platern"
              />
            </Link>
            <h1 className="mb-0 text-primary text-7xl font-black text-center">404</h1>
            <p className="text-primary text-center mb-4">Not found</p>
            <Link to="/" className="m-auto text-center">Return to the main website</Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default NotFoundPage
